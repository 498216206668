import PropTypes from 'prop-types'
import { Box, Typography, Grid } from '@mui/material'

export const Layout = (props: any) => {
  const { children } = props

  return (
    <Box
      component='main'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: 'background.paper',
      }}
    >
      {children}
    </Box>
  )
}

Layout.prototypes = {
  children: PropTypes.node,
}

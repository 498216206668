import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { useUserContext } from './useUserContext'

export default function useAuthentication() {
  const { user } = useUserContext()
  const router = useRouter()

  useEffect(() => {
    if (!user) {
      if (router.pathname === '/login' || router.pathname === '/register')
        return
      router.push('/login')
    } else {
      if (router.pathname === '/login' || router.pathname === '/register')
        router.push('/app')
    }
  }, [router])
}

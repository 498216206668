import { useTheme } from '@mui/material/styles'

export const Logo = () => {
  const theme = useTheme()
  const fillColor = theme.palette.primary.main

  return (
    <svg
      id='b'
      xmlns='http://www.w3.org/2000/svg'
      width='160'
      height='46'
      viewBox='0 0 240 46'
    >
      <g id='c'>
        <rect width='240' height='46' fill='#fff' opacity='0' strokeWidth='0' />
        <path
          d='m2.42,26.91c-.07-.11-.29-.44-.21-.79.05-.21.22-.4.54-.62,4.35-2.92,7.41-7.01,10.11-11.4.21-.34.04-.55-.07-.81-1.26-3.18-1-6.36-.01-9.55.1-.31.26-.33.54-.32,3.55.2,6.67,1.39,9.1,4.07.28.31.49.36.89.23,4.21-1.42,8.43-1.61,12.65,0,.38.15.55.04.8-.24,2.45-2.8,5.7-3.78,9.26-4.06.34-.03.42.13.5.39.9,3.12,1.13,6.23-.05,9.33-.17.45-.15.78.11,1.19,2.29,3.7,4.72,7.28,8.25,9.95.63.47,1.25.95,1.91,1.37.19.12.3.23.33.36,0,0,.05.21-.11.48-4.34,6.95-12.04,10.83-12.04,10.83-1.82.92-3.88,1.85-6.15,2.7-3.16,1.18-6.1,1.99-8.7,2.54-2.5-.55-5.24-1.28-8.16-2.28-2.28-.78-4.38-1.62-6.28-2.47,0,0-8.59-3.91-13.19-10.92Z'
          fill='#001837'
          strokeWidth='0'
        />
        <path
          d='m30.43,17.65c-.25-1.15-.5-2.3-.75-3.45-.05,0-.11,0-.16,0-.19.98-.39,1.97-.58,2.95-.37.24-.47-.11-.6-.31-.22-.34-.37-.72-.51-1.11-.04.7.18,1.4-.13,2.07-.39.15-.53-.19-.69-.41-2.37-3.3-6.3-3.35-9.22-1.86-1.39.71-2.6,1.66-3.78,2.65-.5.42-1.02.81-1.4,1.06.65-.51,1.59-.81,2.56-1.04.26-.06.58-.15.74.13.14.25-.1.47-.27.62-.42.35-.64.97-1.31.96-.58-.34-1.13.09-1.7.09-.47,0-.94.15-1.56.26,2.33-2.67,3.88-5.73,6.14-8.25.29-.32.54-.71.9-.93.81-.51.86-1.07.5-1.93-.45-1.09-1.04-2.07-1.7-3.02.29-.3.49,0,.69.12.85.5,1.65,1.08,2.27,1.86.13.17.26.34.47.41.55.58.91,1.27,1.3,1.95.06.45-.34.56-.53.75,2.15-.72,4.42-1.18,6.73-.89,1.14.14,2.17.07,3.29-.14,1.77-.33,3.56.08,5.3.53.57.15,1.15.28,1.69.52-.16-.26-.58-.34-.55-.75.64-1.07,1.4-2.05,2.28-2.94.43-.25.8-.59,1.24-.83.22-.12.42-.36.71-.15-.44,1.1-1.02,2.13-1.32,3.29-.09.36-.1.59.21.84,1.51,1.26,2.69,2.81,3.72,4.47,1.09,1.74,2.31,3.39,3.68,4.96-1.12.08-2.15-.3-3.21-.19-.84-.22-1.25-.98-1.86-1.49-.07-.06-.11-.19-.03-.29.12-.15.3-.13.45-.08.78.24,1.58.4,2.3.71-1.23-1.11-2.5-2.2-3.98-3.05-2.01-1.15-4.09-1.51-6.33-.77-1.5.5-2.5,1.57-3.42,2.76-.18.23-.23.57-.59.61-.46-.27-.33-.65-.11-.97.36-.52.42-1.08.37-1.74-.28.48-.45.97-.69,1.41-.13.23-.15.6-.56.6Z'
          fill='#578fb8'
          strokeWidth='0'
        />
        <path
          d='m14.32,19.92c.61-.44,1.11-.98,1.78-1.7-1.56.3-2.81.72-4,1.39,1.41-1.24,2.78-2.52,4.37-3.54,1.46-.94,3.01-1.59,4.77-1.67,2.54-.1,4.51.91,6,2.94.13.17.27.34.4.51.84.67.87,1.65.97,2.6-.34.24-.45-.13-.62-.26-2.84-2.19-6.08-2.21-8.84.04-1.67,1.37-3.39,2.66-5.28,3.81.35.23.64-.08,1.02.04-.87,1.03-1.91,1.8-2.87,2.67-1.24-.19-2.51-.21-3.72-.61-.55-.1-1.11-.21-1.84-.34,2.72-2.01,5.5-3.67,7.85-5.88Z'
          fill='#fdfdfd'
          strokeWidth='0'
        />
        <path
          d='m21.15,31.65c1.81-2.87,4.02-5.34,7.07-6.96.87-.46,1.74-.45,2.64-.04,2.25,1.02,3.93,2.74,5.53,4.54.68.76,1.33,1.56,1.84,2.46.22.41-.03.63-.36.74-1.55.48-3.13.82-4.75.96-.78.07-.55-.64-.71-1.04-.12-.43-.33-.82-.56-1.21-1.24-1.47-3.42-1.4-4.66.16-.17.28-.31.58-.4.9-.19,1.06-.19,1.07-1.28,1.02-1.35-.07-2.65-.41-3.95-.77-.37-.1-.65-.31-.42-.76Z'
          fill='#fdfdfd'
          strokeWidth='0'
        />
        <path
          d='m31.38,18.26c.96-1.42,2.03-2.73,3.7-3.37,2.7-1.04,5.14-.42,7.46,1.1,1.34.88,2.54,1.92,3.65,3.12-1.03-.31-2.07-.62-3.34-1,.79.73,1.41,1.28,2.02,1.84,1.64,1.63,3.56,2.89,5.45,4.19.78.53,1.57,1.04,2.41,1.61-.57.22-1.07.38-1.62.39-2.46.51-4.15-.97-5.89-2.3-1.64-.8-3.13-1.84-4.5-3.02-3.03-2.6-5.71-3.46-9.97-.25-.03-.84-.21-1.72.63-2.32Z'
          fill='#fdfdfd'
          strokeWidth='0'
        />
        <path
          d='m50.44,26.28c.62.47,1.41.49,2.11.73.43.15.49.27.22.65-2.8,3.95-6.31,7.03-10.85,8.85-1.23.49-2.48.94-3.78,1.22-.26.06-.51.16-.91.02,2.48-1.69,4.88-3.34,7.29-4.99-1.56.25-3.07.34-4.61.05,2.9-1.82,6.04-3.1,9.21-4.56-.77-.5-1.53-.95-2.13-1.59.43-.49,1.06-.58,1.62-.55.6.03,1.23,0,1.82.17Z'
          fill='#5a8fb5'
          strokeWidth='0'
        />
        <path
          d='m8.3,26.14c1.25.14,2.55-.03,3.72.61-.58.52-1.16,1.03-1.83,1.62,3.09,1.42,6.24,2.6,9.24,4.43-1.59.23-3.02.28-4.66-.24,2.38,1.99,4.88,3.41,7.29,5.21-.63.15-1.06-.06-1.47-.17-5.79-1.61-10.45-4.86-14.03-9.69-.41-.55-.48-.85.32-1.01.59-.12,1.17-.34,1.78-.53-.14-.09-.25-.16-.36-.23Z'
          fill='#598fb9'
          strokeWidth='0'
        />
        <path
          d='m37.58,10.5c.42.33.83.67,1.25,1l-.05.11c-1.1-.29-2.19-.61-3.29-.88-1.32-.32-2.67-.42-4.02-.38-.13,0-.27.02-.4.03-.61.04-1.12.64-1.59.6-.48-.04-1.01-.45-1.58-.48-2.27-.13-4.47.2-6.62.93-.2.07-.4.14-.67.23.17-.62.81-.72,1.05-1.19,5.32-2.98,10.63-3,15.94.02Z'
          fill='#98e5fc'
          strokeWidth='0'
        />
        <path
          d='m21.15,31.65c-.03.33.14.49.45.55,1.36.3,2.7.7,4.12.76.58.02.92-.03.81-.71-.01-.08.09-.19.13-.28.21.14.23.37.22.58-.02,1.15.71,1.81,1.6,2.22.65.3.72.72.66,1.29-.08.64-.33,1.16-1.03,1.28-.89.16-1.72.02-2.42-.62-.46-.42-.88-.88-1.22-1.4-.1-.15-.23-.37-.44-.25-.25.14-.15.38-.05.57.42.8.97,1.49,1.73,2,.18.12.34.26.48.44.03.05.06.12.03.15-.26.19-.5.38-.72.61-2.15-1.61-3.88-3.53-4.44-6.27-.06-.3-.21-.64.09-.92Z'
          fill='#e9edf0'
          strokeWidth='0'
        />
        <path
          d='m32.57,32.13c.35,1.1.34,1.06,1.62.85,1.13-.19,2.25-.43,3.36-.71.31-.08.73-.11.68-.62.31.68-.02,1.31-.22,1.91-.47,1.41-1.26,2.65-2.36,3.68-.29-.17-.53-.39-.61-.74,0-.33.19-.62.26-.93.04-.18.12-.41-.09-.51-.22-.11-.35.09-.45.24-.35.52-.76.98-1.22,1.41-.67.62-1.47.78-2.34.65-.64-.1-1.03-.47-1.12-1.11-.09-.64-.07-1.2.68-1.54.89-.4,1.59-1.04,1.58-2.16,0-.16.05-.32.21-.41Z'
          fill='#ebedf0'
          strokeWidth='0'
        />
        <path
          d='m35.14,36.52c.18.23.54.34.51.72-2.43,3.12-7.19,3.86-10.16,1.59q-.18-.49.69-.64c.35-.21.68.06,1.02.04,1.58-.11,3.15-.02,4.73-.02.96,0,1.78-.58,2.49-1.23.21-.19.34-.51.71-.46Z'
          fill='#d7e1ea'
          strokeWidth='0'
        />
        <path
          d='m20,23.66c.09-.76.45-1.33,1.23-1.47.83-.15,1.37.31,1.74,1.01.3.59.18,1.13-.29,1.54-.52.46-1.17.59-1.82.29-.57-.26-.99-.66-.86-1.37Z'
          fill='#f9fcfc'
          strokeWidth='0'
        />
        <path
          d='m36.09,23.36c.26-.69.7-1.16,1.48-1.18.75-.02,1.21.39,1.5,1.03.17.82,0,1.33-.64,1.74-.56.36-1.14.32-1.68-.03-.56-.36-.93-.84-.66-1.56Z'
          fill='#fbfcfc'
          strokeWidth='0'
        />
        <path
          d='m50.44,26.28c-1.18-.18-2.31.14-3.44.38-1.01-.75-1.95-1.56-2.74-2.55.32-.27.74.1.97-.27,1.32.72,2.63,1.47,3.97,2.15.59.3,1.28.03,1.92.14-.2.17-.45.11-.68.15Z'
          fill='#e8ebee'
          strokeWidth='0'
        />
        <path
          d='m36.09,23.36c.16,1.06.72,1.67,1.54,1.65.84-.02,1.4-.72,1.45-1.8.5.86.35,1.72-.42,2.37-.66.55-1.54.53-2.21-.06-.69-.61-.82-1.38-.35-2.15Z'
          fill='#0d70aa'
          strokeWidth='0'
        />
        <path
          d='m20,23.66c.46,1.25,1.23,1.62,2.27,1.12.71-.34.68-.96.69-1.58.73.56.53,1.22.17,1.86-.38.68-1.38,1.05-2.11.81-1.07-.35-1.43-1.12-1.03-2.21Z'
          fill='#1275ba'
          strokeWidth='0'
        />
        <path
          d='m28.6,20.45c-.22-.9-.55-1.77-.97-2.6.06-.88.06-1.76-.16-2.76.45.3.54.67.73,1,.22.37.18.93.74,1.06-.24,1.09-.33,2.19-.34,3.3Z'
          fill='#2b648b'
          strokeWidth='0'
        />
        <path
          d='m31.38,18.26c-.33.74-.58,1.5-.63,2.32-.07.09-.16.05-.16-.03-.06-.97-.11-1.93-.17-2.9.42-.83.83-1.66,1.25-2.49.16.78.44,1.55-.15,2.23-.22.25-.4.52-.15.86Z'
          fill='#2e6b95'
          strokeWidth='0'
        />
        <path
          d='m20.34,8.53c-.55.05-.71-.46-1.03-.74-.74-.65-1.44-1.34-2.41-1.66-.22-.19-.44-.38-.67-.57.05-.06.1-.11.15-.17,1.55.74,3,1.61,3.96,3.13Z'
          fill='#c6e7f3'
          strokeWidth='0'
        />
        <path
          d='m41.8,6.59c-.71.2-1.17.91-1.95.98.73-1.11,1.9-1.6,3.18-2.3-.38.66-.86.95-1.24,1.32Z'
          fill='#a8cae0'
          strokeWidth='0'
        />
        <path
          d='m35.14,36.52c-1.08,1.28-2.39,2.18-4.14,1.97-1.01-.13-1.99-.22-2.99,0-.62.14-1.23-.12-1.82-.3-1.1-.59-1.9-1.47-2.47-2.57-.17-.34-.26-.69.16-.9.41-.21.56.12.74.4.7,1.09,1.47,2.11,2.96,2.08,1.08-.02,1.67-.86,1.35-1.87-.06-.2-.21-.21-.35-.27-1.54-.68-2.01-1.44-1.93-3.08.13-.27.27-.54.4-.81.54-.57,1.17-.63,1.85-.32.44.2.86.23,1.33.05.58-.21,1.17-.06,1.75.06.31.33.43.76.58,1.17.16,1.35-.5,2.27-1.67,2.74-.83.33-.58.91-.58,1.45,0,.57.46.77.93.85.9.14,1.73-.06,2.37-.75.4-.44.74-.93,1.07-1.41.19-.28.39-.44.73-.29.35.15.26.46.19.7-.12.38-.31.74-.47,1.11Z'
          fill='#011938'
          strokeWidth='0'
        />
        <path
          d='m31.99,30.96c-1.13-.04-2.27.59-3.4,0-.56-.29-1.04.03-1.54.2.95-1.65,3.67-1.76,4.93-.2Z'
          fill='#1c81ce'
          strokeWidth='0'
        />
        <path
          d='m61.95,14.65h6.2c1.88,0,3.36.27,4.45.81,1.09.54,1.88,1.41,2.36,2.61.49,1.2.73,2.84.73,4.93s-.24,3.73-.73,4.93c-.49,1.2-1.27,2.07-2.36,2.61s-2.57.81-4.45.81h-6.2V14.65Zm6.2,14.08c1.05,0,1.88-.18,2.48-.55.6-.37,1.04-.96,1.3-1.79.26-.83.39-1.96.39-3.39s-.13-2.56-.39-3.39c-.26-.83-.7-1.42-1.3-1.79-.61-.37-1.43-.55-2.48-.55h-2.84v11.45h2.84Z'
          fill='#001837'
          strokeWidth='0'
        />
        <path
          d='m87.68,27.46h-6.54l-1.12,3.89h-3.36l4.08-13.31c.29-.95.59-1.69.91-2.22.32-.53.7-.9,1.13-1.12.44-.22.98-.33,1.63-.33s1.19.11,1.62.33c.43.22.81.6,1.13,1.12.33.52.63,1.26.92,2.22l4.08,13.31h-3.36l-1.12-3.89Zm-.74-2.5l-2-6.87c-.11-.41-.29-.62-.53-.62s-.41.21-.53.62l-2,6.87h5.06Z'
          fill='#001837'
          strokeWidth='0'
        />
        <path
          d='m96,17.27h-5.03v-2.62h13.43v2.62h-5.03v14.08h-3.36v-14.08Z'
          fill='#001837'
          strokeWidth='0'
        />
        <path
          d='m114.23,27.46h-6.54l-1.12,3.89h-3.36l4.08-13.31c.29-.95.59-1.69.91-2.22.32-.53.7-.9,1.13-1.12.44-.22.98-.33,1.63-.33s1.19.11,1.62.33c.43.22.81.6,1.13,1.12.33.52.63,1.26.92,2.22l4.08,13.31h-3.36l-1.12-3.89Zm-.74-2.5l-2-6.87c-.11-.41-.29-.62-.53-.62s-.41.21-.53.62l-2,6.87h5.06Z'
          fill='#001837'
          strokeWidth='0'
        />
        <path
          d='m141.91,31.35h-3.56l-1.1-3.7c-.22-.76-.45-1.36-.67-1.78s-.52-.74-.91-.97c-.38-.22-.89-.33-1.53-.33h-2.41v6.78h-3.36V14.65h6.75c2.04,0,3.54.4,4.51,1.21s1.46,2.06,1.46,3.76c0,1.1-.29,1.98-.87,2.65-.58.67-1.38,1.1-2.4,1.29.81.27,1.42.74,1.84,1.41.41.67.8,1.57,1.15,2.72l1.1,3.67Zm-4.88-9.99c.47-.39.7-.97.7-1.75s-.24-1.36-.7-1.75c-.47-.39-1.07-.58-1.8-.58h-3.48v4.68h3.48c.73,0,1.33-.19,1.8-.58Z'
          fill='#001837'
          strokeWidth='0'
        />
        <path
          d='m154.37,27.46h-6.54l-1.12,3.89h-3.36l4.08-13.31c.29-.95.59-1.69.91-2.22.32-.53.7-.9,1.13-1.12.44-.22.98-.33,1.63-.33s1.19.11,1.62.33c.43.22.81.6,1.13,1.12.33.52.63,1.26.92,2.22l4.08,13.31h-3.36l-1.12-3.89Zm-.74-2.5l-2-6.87c-.11-.41-.29-.62-.53-.62s-.41.21-.53.62l-2,6.87h5.06Z'
          fill='#001837'
          strokeWidth='0'
        />
        <path
          d='m161.21,30.1c-1.18-1.03-1.77-2.54-1.77-4.55v-5.11c0-2,.59-3.52,1.77-4.55,1.18-1.03,2.89-1.54,5.13-1.54,1.56,0,2.84.26,3.83.77.99.52,1.79,1.29,2.37,2.33l-2.41,1.67c-.49-.76-1.01-1.31-1.54-1.65-.53-.33-1.28-.5-2.26-.5-1.23,0-2.12.29-2.68.87-.57.58-.85,1.44-.85,2.59v5.11c0,1.15.28,2.01.85,2.59.56.58,1.46.87,2.68.87,1.05,0,1.86-.18,2.45-.54s1.1-.94,1.56-1.73l2.39,1.77c-.51.73-1.03,1.32-1.57,1.77-.54.45-1.2.78-1.97,1.01-.77.23-1.72.35-2.85.35-2.24,0-3.95-.51-5.13-1.54Z'
          fill='#001837'
          strokeWidth='0'
        />
        <path
          d='m176.65,30.1c-1.18-1.03-1.77-2.54-1.77-4.55v-5.11c0-2,.59-3.52,1.77-4.55,1.18-1.03,2.89-1.54,5.13-1.54,1.56,0,2.83.26,3.83.77.99.52,1.78,1.29,2.37,2.33l-2.41,1.67c-.49-.76-1.01-1.31-1.54-1.65-.53-.33-1.28-.5-2.26-.5-1.23,0-2.12.29-2.68.87s-.85,1.44-.85,2.59v5.11c0,1.15.28,2.01.85,2.59s1.46.87,2.68.87c1.05,0,1.86-.18,2.45-.54s1.1-.94,1.56-1.73l2.39,1.77c-.51.73-1.03,1.32-1.57,1.77-.54.45-1.2.78-1.97,1.01-.77.23-1.72.35-2.85.35-2.24,0-3.95-.51-5.13-1.54Z'
          fill='#001837'
          strokeWidth='0'
        />
        <path
          d='m192.08,30.1c-1.17-1.03-1.75-2.54-1.75-4.55v-5.11c0-2,.58-3.52,1.75-4.55,1.17-1.03,2.86-1.54,5.07-1.54s3.9.51,5.07,1.54c1.17,1.03,1.75,2.54,1.75,4.55v5.11c0,2-.58,3.52-1.75,4.55-1.17,1.03-2.86,1.54-5.07,1.54s-3.9-.51-5.07-1.54Zm7.64-1.98c.6-.6.9-1.45.9-2.56v-5.11c0-1.11-.3-1.97-.9-2.56-.6-.6-1.45-.9-2.56-.9s-1.97.3-2.56.9c-.6.6-.9,1.45-.9,2.56v5.11c0,1.11.3,1.97.9,2.56s1.45.89,2.56.89,1.97-.3,2.56-.89Z'
          fill='#001837'
          strokeWidth='0'
        />
        <path
          d='m208.59,30.1c-1.17-1.03-1.75-2.54-1.75-4.55v-5.11c0-2,.58-3.52,1.75-4.55,1.17-1.03,2.86-1.54,5.07-1.54s3.9.51,5.07,1.54c1.17,1.03,1.75,2.54,1.75,4.55v5.11c0,2-.58,3.52-1.75,4.55-1.17,1.03-2.86,1.54-5.07,1.54s-3.9-.51-5.07-1.54Zm7.63-1.98c.6-.6.9-1.45.9-2.56v-5.11c0-1.11-.3-1.97-.9-2.56-.6-.6-1.45-.9-2.56-.9s-1.97.3-2.56.9c-.6.6-.89,1.45-.89,2.56v5.11c0,1.11.3,1.97.89,2.56.6.6,1.45.89,2.56.89s1.97-.3,2.56-.89Z'
          fill='#001837'
          strokeWidth='0'
        />
        <path
          d='m237.81,14.65v12.77c0,1.29-.26,2.27-.79,2.93-.52.67-1.37,1-2.53,1h-2.36l-4.92-11.38v11.38h-3.15V14.65h3.79l6.06,13.84h.05c.27,0,.45-.11.55-.35.09-.23.14-.65.14-1.25v-12.24h3.15Z'
          fill='#001837'
          strokeWidth='0'
        />
      </g>
    </svg>
  )
}
